<template>
  <section>
    <screenshot name-pdf="Histórico Blending"></screenshot>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table
          :configUrl="config"
          :dataUrl="data"
          :editUrl="edit"
        ></ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, VBPopover, VBTooltip } from "bootstrap-vue";
import Screenshot from "@/views/clients/fiordo/components/Screenshot";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import HistoricoBlendingActions from "./HistoricoBlendingActions.vue";
export default {
  name: "Histórico Blending",
  components: {
    BRow,
    BCol,
    VBPopover,
    VBTooltip,
    Screenshot,
    VBPopover,
    VBTooltip,
    AgGridTable,
    RepeatStatistics,
    HistoricoBlendingActions: HistoricoBlendingActions,
  },
  data() {
    return {
      config: useApiServices.historicoBlendingConfig,
      data: useApiServices.historicoBlendingData,
      edit: useApiServices.historicoBlendingEdit,
      itemsData: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted() {
    axios
      .get(useApiServices.historicoBlendingStatistics)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => {});
  },
};
</script>

<style lang="scss"></style>
